import React from "react"
import { graphql } from "gatsby"
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
} from '../ui-components';
import {
  Wrapper,
  Container,
  DescriptionMain,
} from '../components/mission-statement/mission-statement.styled';
import FeaturesMini from "../components/features/features-mini";
import PreOrder from "../components/pre-order/pre-order";
import Specifications from "../components/specifications/specifications";
import BetritonMini from '../../content/betriton-mini.json';
import NorwayGrantsSrc from "../assets/img/Norway-grants.png"
import { markdown2HTML } from '../utils';


const MiniPage = ({ data }) => {
  const content = data.markdownRemark?.frontmatter || {};
  const siteTitle = `BeTRITON - Mini - DIY Kit`
  const gallery = data.gallery.edges.map(v => v.node.childMarkdownRemark.frontmatter) || [];

  return (
    <>
      <SEO
        canonical="/diy-kit"
        title={siteTitle}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>
          <Wrapper>
            <Container>
              <PageTitle custom dangerouslySetInnerHTML={{ __html: markdown2HTML(content.title) }} />
              <DescriptionMain dangerouslySetInnerHTML={{ __html: content.description }} />
            </Container>
          </Wrapper>
          <Wrapper paper>
            <Container>
              <FeaturesMini gallery={gallery} />
              <Specifications content={BetritonMini} />
              <div style={{ textAlign: "center" }} >
                <img src={NorwayGrantsSrc} alt="Norway Grants" style={{ maxWidth: "200px", margin: "auto" }} />
              </div>
              <PageHTML>
                <p style={{ textAlign: "center", fontStyle: "italic" }}><small>Working together for a green, competitive and inclusive Europe.</small></p>
                <p style={{ textAlign: "center" }}><small>BeTRITON SIA receives a grant of EUR 75,000 (total project funding of EUR 106,500) from the Norwegian grant "Development of Green Innovation and Information and Communication Technology Products", contract no. NP-2022/2, 26.01.2022, project goal - Development of a new sustainable tourism and mobility solution "BeTRITON DIY".</small></p>

                <p style={{ textAlign: "center" }}><strong>2022 JAN – JULY</strong></p>
                <p style={{ textAlign: "center" }}><small>Project implementation commenced.<br />
                A dedicated website section was created as a project component.<br />
                Research and development activities, including technology and material sample testing, were conducted in collaboration with our Norwegian partner and Vidzeme University.<br />
                Initial work on developing the first iteration of the prototype began</small></p>

                <p style={{ textAlign: "center" }}><strong>2022 JUL – 2023 JAN</strong></p>
                <p style={{ textAlign: "center" }}><small>Completion of the first iteration of the prototype, followed by rigorous testing.<br />
                Presentation of the product concept and implementation to the media, including outreach to the Netherlands.<br />
                Initiation of development for the second iteration of the product prototype.</small></p>

                <p style={{ textAlign: "center" }}><strong>JAN - JULY 2023</strong></p>
                <p style={{ textAlign: "center" }}><small>Significant enhancements made to product technology and design through collaborative efforts with our Norwegian partner.<br />
                Participation in a workshop organized by Deep Tech Atelier alongside Norwegian specialists, leading to further improvements in design.<br />
                Continued development of the second iteration of the prototype.</small></p>

                <p style={{ textAlign: "center" }}><strong>2023 JUL - 2024 JAN</strong></p>
                <p style={{ textAlign: "center" }}><small>Completion of the second iteration of the product prototype and successful product testing.<br />
                Preparation of visual materials, including photos and audio, for product presentation.<br />
                Research conducted on sustainable material suppliers in collaboration with our project partner, Noveltex Oslo.</small></p>
              </PageHTML>
            </Container>
          </Wrapper>
          <PreOrder />
        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}

export default MiniPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/content/pages/betriton_mini.md/"}) {
      id
      frontmatter {
        title
        description
      }
      html
    }
    gallery: allFile(filter: {absolutePath: {regex: "/content/gallery/betriton_mini/"}},  sort: {fields: childrenMarkdownRemark___frontmatter___order, order: ASC}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              type
              youtube
              image{ 
                childImageSharp {
                  thumb: gatsbyImageData(
                    width: 187
                    height: 140
                    quality: 100
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                  fullscreen: gatsbyImageData(
                    placeholder: BLURRED
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
