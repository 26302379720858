import React, { useState } from "react";
import {
  Button,
} from '../../ui-components';
import {
  FeatureTitle,
  Wrapper,
  Container,
} from './features.styled';
import Gallery from "../gallery/gallery";
import GalleryFullScreen from "../gallery/gallery-fullscreen"
import FeatureViewer from "../feature-viewer.js/feature-viewer";

const FeaturesMini = ({ gallery }) => {
  const [fullScreenIndex, setFullScreenIndex] = useState(null);
  const data = [
    {
      "type": "youtube",
      "youtube": "https://www.youtube.com/embed/QJHgW-qOE1s"
    }
  ]
  const fullScreenData = fullScreenIndex !== null ? data[fullScreenIndex] : null;

  const enterFullScreen = () => {
    setFullScreenIndex(0);
  }

  const handleClose = (event) => {
    event.preventDefault();
    setFullScreenIndex(null);
  }

  return (
    <>
      {fullScreenData
        ? (
          <GalleryFullScreen
            data={{
              type: fullScreenData.type,
              youtube: fullScreenData.youtube,
            }}
            disableNavigation
            onNext={() => { }}
            onPrev={() => { }}
            onClose={handleClose}
            forcedType="youtube"
          />
        )
        : ''}

      <Wrapper>
        <a name="product" />
        <Container>
          <FeatureTitle>Amphibious Camper-Trike</FeatureTitle>
          <FeatureViewer domain="diy" />
        </Container>


        <Container center margin>
          <Button
            type="button"
            onClick={enterFullScreen}
            target="_blank"
            size="large"
            color="secondary"
          >
            The first prototype LAUNCH
          </Button>
        </Container>

        <br />

        <Container>
          <Gallery data={gallery} />
        </Container>

      </Wrapper>
    </>
  )
}

export default FeaturesMini;
